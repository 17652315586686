/* @media screen and (max-width: 767px) { */
  .aboutHome {
    height: auto;
  }
  .aboutHome #heading {
    text-align: left;
    padding: 0;
  }
  .aboutHome .left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .aboutHome .right {
    padding: 80px 50px;
  }
  .aboutHome .items {
    margin-top: 50px;
  }
  .aboutHome .item {
    background-color: #fff;
    margin-top: 30px;
    padding: 20px;
    transition: 0.5s;
  }
  .aboutHome .img {
    width: 200px;
  }
  .aboutHome img {
    width: 70px;
    height: 70px;
  }
  .aboutHome .item h2 {
    font-size: 1.1em;
    margin-bottom: 15px;
    margin-left: 4%;
  }
  .aboutHome .item p {
    color: #999999;
    font-size: 1em;

  }
  .aboutHome .item:hover {
    background-color: #1eb2a6;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  }
  .aboutHome .item:hover p {
    color: #fff;
  }
  /*----------awrapper------------*/
  .awrapper {
    background-image: url("../../../public/images/awrapper.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 30vh;
    color: #fff;
  }
  .awrapper .box {
    padding: 70px 0;
  }
  .awrapper img {
    margin-right: 30px;
  }
  .awrapper h1 {
    font-size: 46px;
  }
  .awrapper h3 {
    font-size: 20px;
    font-weight: 500;
  }
/* } */


/*----------awrapper------------*/
@media screen and (max-width: 768px) {
  .aboutHome .container {
    flex-direction: column-reverse;
  }
  .aboutHome .row {
    width: 100%;
  }
  .awrapper {
    height: 50vh;
  }
}
