/* .hero {
  background-image: url("../../../../public/images/newimg.jpg");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero {
    background-position: right;
    padding-top: 50%;
    height: 80vh;
  }
  .hero .row {
    width: 100%;
  }
} */




.hero {
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero {
    background-position: right;
    padding-top: 50%;
    height: 80vh;
  }
  .hero .row {
    width: 100%;
  }
}



.hero .slider{
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.slider {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
 }
  
 .slider .slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  animation: slider 7.5s infinite;
 }
  
 .slider .slide:nth-child(1) {
    background-image: url('../../../../public/images/newimg.jpg');
    animation-delay: 0s;
 }
 
 .slider .slide:nth-child(2) {
    background-image: url('../../../../public/images/pinkbg.jpg');
    animation-delay: -2.5s;
 }
 
 .slider .slide:nth-child(3) {
    background-image: url('../../../../public/images/grp.jpg');
    animation-delay: -5s;
 }
 
 @keyframes slider {
  0%, 26%, 100% {
    transform: translateX(0);
    animation-timing-function: ease;
  }
  33% {
    transform: translateX(-100%);
    animation-timing-function: step-end;
  }
  93% {
    transform: translateX(100%);
    animation-timing-function: ease;
  }
 }
